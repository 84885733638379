<template>
  <div class="text-left">
    <BaseHeader :title="'Editors'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-btn
            color="primary"
            class="my-auto ml-2"
            size="small"
            @click.stop="$store.state.dialog = true"
          >New</v-btn> -->
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="editors"
          :search="$store.state.search"
        >
          <template v-slot:[`item.names`]="{ item }">
            <router-link
              v-if="item.id"
              :to="{
                name: 'WriterProfile',
                params: { id: item.id || '' },
              }"
              >{{ item.names || "--" }}</router-link
            >
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip v-if="item.status == '1'" color="primary" outlined small
              >Active</v-chip
            >
            <v-chip v-else color="error" x-small>Inactive</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              elevation="0"
              x-small
              fab
              :to="{ name: 'ViewSystemClient', params: { id: item.id || '' } }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <!-- <v-btn color="error" class="white--text" fab x-small>
                <v-icon small>mdi-delete</v-icon>
              </v-btn> -->
          </template>
        </v-data-table>
        <!-- /datatable -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Editors",
  computed: {
    ...mapState("editors", ["loading", "editors", "headers"]),
  },
  data() {
    return {
      countries: [],
    };
  },

  methods: {
    ...mapActions("editors", ["getEditors"]),
  },
  async mounted() {
    await this.getEditors();
  },
};
</script>